const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');
const moment = require('moment');
const getKTMNRevenue=async(filter)=>{
    try{
        let res= await axios({
            url: config_api.thirdPartyRevenue,
            method: 'get',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            params: filter,
        });
        return [null,res.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }

}
const getKTMTRevenue=async(filter)=>{
    try{
        let res= await axios({
            url: config_api.thirdPartyRevenueKTMT,
            method: 'get',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            },
            params: filter,
        });
        return [null,res.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }

}
const getThirdPartyWins= async(query)=>{
    try{
        let res=await axios({
            url: config_api.thirdPartyWin + "?" + utils.convertToQuery(query),
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + utils.getAuthToken(),
            },
          })
        return [null,res.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }
}
const getThirdPartyWinsExport= async(query)=>{
    try{
        let result=await axios({
            url: config_api.thirdPartyWinExport + "?" + utils.convertToQuery(query),
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + utils.getAuthToken(),
            },
            responseType: 'blob',
          })
          const type = result.headers['content-type'];
        const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const now = moment().format('DD-MM-YYYY HH-mm')
        link.download = `export${now}.xlsx`;
        link.click();
        return [null,result.data];
    }
    catch(error){
        if (error.response) {
            return [error.response];
        } else if (error.request) {
            return ["Đường truyền bị gián đoạn"];
        } else {
            return [error.message];
        }
    }
}

 async function listOrder(productId,statusId, staffId,ticketId,orderId,datePrintFrom,datePrintTo,page,size) {
    try{
        let res = await axios({
            url: `${config_api.thirdPartyOrderManageMent}?productId=${productId??''}&statusId=${statusId??''}&staffId=${staffId??''}&ticketId=${ticketId??''}&orderId=${orderId??''}&datePrintFrom=${datePrintFrom??''}&datePrintTo=${datePrintTo??''}&page=${page}&size=${size}`,
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + utils.getAuthToken()
            }
        })
        return [null,res.data]
    }
    catch(error){
        if (error.response) {
            return error.response;
        } else {
            return [error.message];
        }
    }   
}

export {
    getKTMNRevenue,
    getThirdPartyWins,
    getKTMTRevenue,
    getThirdPartyWinsExport,
    listOrder
}