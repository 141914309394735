
import React,{useEffect,useState} from "react";
import utils from "../../../utils";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  InputGroup,
  Input,
  CustomInput,
  FormGroup,
  Card,
  CardTitle, CardBody,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Edit, Lock, Unlock } from "react-feather";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../components/Notification";
import { Pagination } from 'antd';
import moment from "moment";
import InputMask from "react-input-mask";
const { getNameDistrictFromCode } = require("../../../config/listDistrict");
const { getNameCityFromCode } = require("../../../config/listCity");
const { arrayCity } = require("../../../config/city");

const apiNotifi = require("./../api/apiNotifi");
const apiOrder = require('../api/thirdPartyApi')
const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');

let STATUS=['Tất cả','Chờ in','Đang giữ vé','Đã huỷ','Hoàn thành'];

let PRODUCT =['Tất cả','Mega','Power','','Max3d','Max3d+','Keno','Loto235','Loto234','6x36','Thần tài','123','Max3dPro','KTMB','KTMN','KTMT']
class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        this.props.smallOption ? (
                            <InputGroup className="mb-3 smallOption">
                                <CustomInput type="select" name={"option_"+this.props.name} className="smallOption-statistic d-inline" onChange={this.props.action}>
                                    <option key="0" value={""}>Tất cả</option>
                                    {
                                        this.props.smallOption.map((option,key)=>{
                                            return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                        })
                                    }
                                </CustomInput>
                                <input name={this.props.name||"nullName"} type="number" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                            </InputGroup>
                        ) : (
                            <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                        )
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}
 class OrderManagement extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        data: {},
        showModal: false,
        page:1,
        size:10,
        title: "",
        content: "",
        selectedRow:null,
        frmProductId:null,
        frmStatusId:null,
        frmStaffId:null,
        frmTicketId:null,
        frmOrderId:null,
        frmDatePrintFrom:null,
        frmDatePrintTo:null,
        selectedRow:null
    };
    this.tableheader=[
        {label: "Id Order"},
        {label: "Id Vé"},   
        {label: "Loại vé"},         
        {label: "Kiểu chơi"},
        {label: "Phí giữ vé"},
        {label: "Tổng"},  
        {label: "Nguồn tiền"}, 
        {label: "Tên khách"}, 
        {label: "Số điện thoại"}, 
        {label: "Trạng thái đơn hàng"},                   
        {label: "Thời gian mua"},
        {label: "Thời gian bán"},
        {label: "Trạng thái trúng thưởng"},
        {label: "Chức năng"},
    ];
    this.rowPage = [50,100,200,250];
    }
    fetchData =async()=>{
        let [err,response]=  await apiOrder.listOrder(this.state.frmProductId,this.state.frmStatusId,this.state.frmStaffId,
            this.state.frmTicketId,this.state.frmOrderId,this.state.frmDatePrintFrom,this.state.frmDatePrintTo,this.state.page,this.state.size)
        if(err){
            Notification('error',"Có lỗi xảy ra",err)
        }
        else{
            this.setState({data:response})
        }
    }
  async componentDidMount() {
    // apiNotifi.listNotifiCommon(1, (d) => {
    //   console.log(d);
    //   this.setState({ ...this.state, data: d });
    // });
    this.fetchData()
    
}
  componentWillUnmount(){
    
  }
 

  toggle = () => {
    this.setState({ ...this.state, showModal: !this.state.showModal });
  };
  onInputChange = (event) => {
    console.log(event);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
   
   
  

    handleClose =()=>{
        this.setState({showModal:false,selectedRow:null})
        this.fetchData();
    }
    getWiningStatus=(item)=>{
        let winingInfo = item.thirdPartyTickets[0].winingNumber
        let termArray = item.thirdPartyTickets[0].termArray
        let isWin = false
        let isCheck = false
        let termProfit = 0
        winingInfo.map((e, i) => {
            let [setOfNumber, term, profit, winingNumberArray] = e
            if ( profit > 0) isWin = true
        })
        return { isWin, isCheck, termProfit }
    }
    handleEdit =(item)=>{
        this.setState({
            showModal:true,
            selectedRow:item
        })
    }   


    render() {
        return (
            <React.Fragment>
                
                <Modal  size="lg" isOpen={this.state.showModal}>
                  {  
                       this.state.selectedRow&& <>
                        <ModalHeader toggle={()=>this.setState({showModal:false,selectedRow:null})}>
                            <div className="font-weight-bold">
                            {
                                `Vé: ${getProductById(this.state.selectedRow.productId)} `
                            }
                            </div>
                        </ModalHeader>
                        <ModalBody>
                        {
                            this.state.selectedRow.thirdPartyTickets.map((ticket,key)=>{
                                let price=0;
                                for(let val of ticket.value){
                                    price+=val.price;
                                }
                                price*=ticket.termArray.length;
                                return(
                                    <Card key = {key}>
                                        <CardTitle>{`Kiểu chơi: ${getProductOptionById(ticket.productOptionId)}, Tiền vé:${utils.getMoneyFormat(price)}, Số kỳ: ${ticket.termArray.length}, Id:${ticket.id} `}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                                ticket.value.map((rowNumber,index)=>{
                                                    return(
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                <span class="text-danger">{String.fromCharCode(65+index)}</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                            
                                                                {![13,14,15].includes(ticket.productId)?
                                                                    rowNumber.number.map((number,k)=>{
                                                                        if(ticket.productOptionId===82){
                                                                            let kenoEvenOddBigSmall={
                                                                                "1": "Lẻ",
                                                                                "2": "Chẵn",
                                                                                "3": "Nhỏ",
                                                                                "4": "Lớn",
                                                                                '5':'Hoà',
                                                                                '6':'Chẵn-Lẻ',
                                                                                '7':'Hoà LN',
                                                                                '8':'Chẵn 11-12',
                                                                                '9':'Lẻ 11-12'
                                                                            };
                                                                            return (<span class="mx-1" key={k}>{kenoEvenOddBigSmall[number]}</span>)
                                                                        }
                                                                        return(<span class="mx-1" key={k}>{number}</span>)
                                                                    }):(<span class="mx-1">{rowNumber.number}</span>)
                                                                }
                                                                { ![13,14,15].includes(ticket.productId)?<span>-{rowNumber.price}K</span>: (<><span>-{rowNumber.price}K</span> <div>{rowNumber.ticketCodes&&rowNumber.ticketCodes.join('; ')}</div></>)
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" style={{width:100}} src={
                                                        
                                                        [14,15].includes(ticket.productId)? utils.getURLImage(ticket.imageFront):
                                                            utils.getURLImage3rd(ticket.imageFront)
                                                        }/>
                                                </Col>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" style={{width:100}} src={[14,15].includes(ticket.productId)? utils.getURLImage(ticket.imageBack):
                                                            utils.getURLImage3rd(ticket.imageBack)}/>
                                                </Col>
                                            </Row>
                                            
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                                }
                        </ModalBody>
                        </>
                    }
                </Modal>
                <div className="d-flex justify-content-end">
                    <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Trạng thái</Label>
                            <Select
                                    name='frmStatusId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={ 
                                        STATUS.map((e,i)=>{
                                            return {
                                                value:i,
                                                label:e
                                            }
                                        })
                                       }
                                    onChange={(value)=>{
                                        if(value.value==0)
                                            value.value =null
                                       this.setState({frmStatusId:value.value});                                       
                                    }}
                                />
                        </FormGroup>
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Sản phẩm</Label>
                            <Select
                                    name='frmProductId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={ 
                                        PRODUCT.map((e,i)=>{
                                            return {
                                                value:i,
                                                label:e
                                            }
                                        })
                                       }
                                    onChange={(value)=>{
                                        if(value.value==0)
                                            value.value =null
                                       this.setState({frmProductId:value.value});                                       
                                    }}
                                />
                        </FormGroup>
                    <Button color='success' onClick={()=>this.fetchData()}>
                        Tìm kiếm
                    </Button> 
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                <Row className='pt-2 p-1 scroll-table  '>
                    <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                        <thead>
                            <tr>
                                {
                                    this.tableheader.map((header,key)=>{
                                        return (
                                            <th >{header.label}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                utils.isEmpty(this.state.data?.data)  ? null :
                                this.state.data.data.map((row)=>{
                                    return(
                                        <tr >
                                            <td>{row.id}</td>
                                            <td>{row.thirdPartyTickets[0].id}</td>
                                            <td>{getProductById( row.thirdPartyTickets[0].productId)}</td>
                                            <td>{getProductOptionById( row.thirdPartyTickets[0].productOptionId)}</td>
                                            <td>{(row.servicePrice*1000).getMoneyFormat()}</td>
                                            <td>{(row.totalPrice*1000).getMoneyFormat()}</td>
                                            <td>-</td>
                                            <td>{row.shipment.fullName}</td>
                                            <td>{row.shipment.phoneNumber}</td>
                                            <td>
                                                {row.thirdPartyTickets[0].statusId==1&&<div className ='badge badge-info'>Chờ in</div>}
                                                {row.thirdPartyTickets[0].statusId==2&&<div className="badge badge-warning">Đang cầm</div>}
                                                {row.thirdPartyTickets[0].statusId==4&&<div className="badge badge-success">Hoàn thành</div>}
                                                {row.thirdPartyTickets[0].statusId==3&&<div className="badge badge-danger">Đã huỷ</div>}
                                            </td>
                                            <td>{row.thirdPartyTickets[0].createdDate}</td>
                                            <td>{row.thirdPartyTickets[0].datePrint}</td>
                                            <td>
                                                { !this.getWiningStatus(row).isCheck&& 'Chưa dò'}
                                                { this.getWiningStatus(row).isCheck&& this.getWiningStatus().isWin&&'Trúng thưởng'}
                                                { this.getWiningStatus(row).isCheck&& !this.getWiningStatus().isWin&& 'Không trúng'}
                                            </td>
                                            <td>
                                                <Edit
                                                    className='text-info float-right mr-2'
                                                    onClick={()=>this.handleEdit(row)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        
                        </tbody>
                    </Table>
                </Row>
                </div>
                <div>
                <Pagination
                    //locale={{ items_per_page: '/ Trang' }}
                    showQuickJumper
                    showSizeChanger
                    total={this.state.data?.total}
                    defaultPageSize={this.state.size}
                    defaultCurrent={this.state.page}
                    onChange={(page,size)=>{
                        this.setState({page: page, size: size},()=>{
                            this.fetchData()
                        })

                    }}
                />
                </div>
            </React.Fragment>
        );
    }
        
    
}

export default OrderManagement;