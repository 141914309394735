const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');



function getTicketArray(ticket,callback){
    axios({
        url: config_api.ticket,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {
            ticketIds:ticket
        }     

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function getStaffMonitor(date,callback){
    axios({
        url: config_api.staffMonitor+'?date='+date,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }        
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function editSeo(name,data,callback){
    axios({
        url: config_api.admin+'/edit-seo',
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {
            name,
            data:data
        }
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getSeo(callback){
    axios({
        url: config_api.admin+'/seo',
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function litStaff(callback) {
    axios({
        url: config_api.admin,
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function createStaff(data,callback) {
    axios({
        url: config_api.admin+"/register",
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function editStaff(staffId,data,callback) {
    axios({
        url: config_api.admin+"/"+staffId,
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function deleteStaff(staffId,callback) {
    axios({
        url: config_api.admin+"/"+staffId,
        method: 'DELETE',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function getConfig(callback) {
    axios({
        url: config_api.admin+"/config",
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {}
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function updateConfig(data,callback) {
    axios({
        url: config_api.admin+"/config",
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function adjustCustomer(id,data,callback) {
    axios({
        url: `${config_api.admin}/customer/${id}`,
        method: 'PATCH',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else {
            return callback(error.message);
        }
    });
}

function resetPasswordStaff(staffId,callback) {
    axios({
        url: `${config_api.admin}/reset_password`,
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data: {
            staffId: staffId,
            password: "vietluck@"+staffId
        }
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else {
            return callback(error.message);
        }
    });
}




module.exports = {
    litStaff: litStaff,
    createStaff: createStaff,
    editStaff: editStaff,
    deleteStaff : deleteStaff,
    getConfig: getConfig,
    updateConfig:updateConfig,
    adjustCustomer: adjustCustomer,
    resetPasswordStaff:resetPasswordStaff,
    getSeo,
    editSeo,
    getStaffMonitor,
    getTicketArray
};